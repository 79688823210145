import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './HomePage.scss';
import profile from './profile.jpeg';
import gcpBadge from './gcpBadge.png';
import awsBadge from './awsBadge.png';

const HomePage = () => {
  const experiences = [
    {
      company: "CVS Health",
      role: "Software Engineer · Contract",
      period: "Jan 2024-Present",
      current: true
    },
    {
      company: "Tata Consultancy Services",
      role: "Software Engineer · Intern",
      period: "Feb 2017 - Apr 2017"
    },
    {
      company: "Jisnu Communications",
      role: "Software Engineer · Intern",
      period: "Mar 2016 - Jun 2016"
    }
  ];

  const certifications = [
    {
      name: "AWS Solutions Architect Associate",
      issuer: "Amazon Web Services",
      date: "2023",
      badge: awsBadge,
      url: "https://www.credly.com/earner/earned/badge/f69084c1-75b1-411a-9446-3df447892f50"
    },
    {
      name: "Google Cloud Professional Developer",
      issuer: "Google Cloud",
      date: "2023",
      badge: gcpBadge,
      url: "https://www.credly.com/earner/earned/badge/a1808b5c-17d0-4ca9-85ca-ac7e6b5bf23a"
    }
  ];

  const skills = [
    "Full Stack Development",
    "Cloud Architect",
    "LangChain",
    "Python",
    "React",
    "NodeJS",
    "Google Cloud",
    "AWS",
  ];

  const [isTransitioning, setIsTransitioning] = useState(false);
  const [transitionPhase, setTransitionPhase] = useState('initial');
  const [showTransitionText, setShowTransitionText] = useState(false);
  const [transitionPosition, setTransitionPosition] = useState({ x: 0, y: 0 });
  const [isExiting, setIsExiting] = useState(false);
  const navigate = useNavigate();

  const getTargetRoute = () => {
        return '/projects';
  };

  const handleProjectsClick = (e) => {
    e.preventDefault();
    setTransitionPosition({ x: e.clientX, y: e.clientY });
    
    // First trigger exit animations
    setIsExiting(true);
    
    // Then start the circle transition after a delay
    setTimeout(() => {
      setIsTransitioning(true);
      setTransitionPhase('expand');
    }, 400); // Delay circle expansion until after exit animations start

    setTimeout(() => {
      setShowTransitionText(true);
    }, 1000);

    setTimeout(() => {
      navigate('/projects');
    }, 1800);

    setTimeout(() => {
      setShowTransitionText(false);
    }, 1600);

    setTimeout(() => {
      setIsTransitioning(false);
      setTransitionPhase('contract');
    }, 2000);
  };


  const getTransitionVariants = () => ({
    initial: {
      scale: 1,
      x: transitionPosition.x,
      y: transitionPosition.y,
    },
    expand: {
      scale: [1, 100],
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
      transition: { duration: 0.8, ease: [0.4, 0, 0.2, 1] }
    },
    contract: {
      scale: [100, 1],
      x: transitionPosition.x,
      y: transitionPosition.y,
      transition: { duration: 0.1, ease: [0.4, 0, 0.2, 1] }
    }
  });

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  const profileVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        duration: 0.6
      }
    }
  };

  // Exit animation variants
  const exitVariants = {
    exit: {
      opacity: 0,
      y: 20,
      transition: {
        duration: 0.3
      }
    }
  };

  const profileExitVariants = {
    exit: {
      opacity: 0,
      x: -100,
      transition: {
        duration: 0.4
      }
    }
  };

  const gridExitVariants = {
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 0.3
      }
    }
  };

  const staggeredExitVariants = {
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const timelineItemVariants = {
    hidden: { x: -20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <>
    <motion.div 
      className="homepage"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {/* Profile Section - Top 40% */}
      <motion.div className="profile-section" variants={itemVariants}>
        <div className="profile">
          <motion.div 
            className="profile__image"
            variants={profileVariants}
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <img src={profile} alt="Profile" />
          </motion.div>
          <motion.div 
            className="profile__content"
            variants={itemVariants}
          >
            <div className="profile__header">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                Jasvith Chand Anne
              </motion.h1>
              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7 }}
              >
                Software Engineer
              </motion.h2>
            </div>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.9 }}
              className='p'
            >
              Hi 👋🏽, I’m Jasvith Chand Anne, a passionate Software Developer with a knack for crafting innovative solutions and 
              building impactful applications. I thrive on turning ideas into seamless digital experiences, blending creativity with 
              technical expertise to deliver excellence.
            </motion.p>
          </motion.div>
        </div>
      </motion.div>

      {/* Three-Column Grid - Bottom 60% */}
      <motion.div 
        className="content-grid"
        variants={containerVariants}
      >
        <motion.div 
          className="section experience"
          variants={itemVariants}
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <h2>Experience <span>01 Year</span></h2>
          <div className="timeline">
            {experiences.map((exp, index) => (
              <motion.div
                className="timeline__item"
                key={index}
                variants={timelineItemVariants}
                custom={index}
              >
                <div className={`timeline__marker ${exp.current ? 'current' : ''}`} />
                <div className="timeline__content">
                  <h3>{exp.company}</h3>
                  <p>{exp.role}</p>
                  <span className="period">{exp.period}</span>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <motion.div 
          className="section-stack"
          variants={itemVariants}
        >
          <motion.div 
            className="section skills"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <h2>Skills & Expertise</h2>
            <div className="skills__grid">
              {skills.map((skill, index) => (
                <motion.span
                  key={index}
                  className="skill-tag"
                  variants={itemVariants}
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  {skill}
                </motion.span>
              ))}
            </div>
          </motion.div>

          <motion.div 
            className="section education"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <h2>Education</h2>
            <div className="timeline">
              {[
                {
                  school: "Arizona State University",
                  degree: "Masters in Computer Science",
                  period: "2021-2023"
                },
                {
                  school: "Amrita Vishwa Vidyapeetham",
                  degree: "Bachelors Degree in Computer Science",
                  period: "2017-2021"
                }
              ].map((edu, index) => (
                <motion.div
                  className="timeline__item"
                  key={index}
                  variants={timelineItemVariants}
                  whileHover={{ x: 10 }}
                >
                  <div className="timeline__marker" />
                  <div className="timeline__content">
                    <h3>{edu.school}</h3>
                    <p>{edu.degree}</p>
                    <span className="period">{edu.period}</span>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </motion.div>

        <motion.div 
  className="section-stack"
  variants={itemVariants}
>
  {/* Certifications Section */}
    <motion.div 
    className="section certifications"
    variants={itemVariants}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <h2>Certifications</h2>
    <div className="certifications__grid">
      {certifications.map((cert, index) => (
          <motion.div
            key={index}
            className="certification-badge"
            variants={itemVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 300 }}
            onClick={() => window.open(cert.url, '_blank')}  // This is the line handling clicks
            title={`${cert.name} - Click to view credential`}
          >
            <img src={cert.badge} alt={cert.name} />
          </motion.div>
        ))}
    </div>
  </motion.div>

  {/* Projects Section */}
  <motion.div 
    className="section projects"
    variants={itemVariants}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <h2>Projects</h2>
    <motion.div 
      className="projects__content" 
      onClick={handleProjectsClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <span className="title">View All Projects</span>
      <motion.span 
        className="arrow"
        animate={{ x: [0, 5, 0] }}
        transition={{ 
          repeat: Infinity, 
          duration: 1.5,
          ease: "easeInOut"
        }}
      >
        →
      </motion.span>
    </motion.div>
  </motion.div>
</motion.div>
      </motion.div> 

      <AnimatePresence mode="wait">
          {isTransitioning && (
            <motion.div
              className="page-transition"
              variants={getTransitionVariants()}
              initial="initial"
              animate={transitionPhase}
            />
          )}

          {showTransitionText && (
            <motion.div
              className="transition-text"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              Projects
            </motion.div>
          )}
        </AnimatePresence>
    </motion.div>
    </>
  );
};

export default HomePage;