// App.js
import React from 'react';
import CustomCursor from './components/CustomCursor/CustomCursor';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/HomePage';
import About from './pages/About/About';
import ProjectsPage from './pages/Projects/Projects';
import './App.css';

import SmokyBackground from './components/SmokyBackground/SmokyBackground';

const App = () => {
  return (
    <Router>
        <div className="app">
          <CustomCursor/>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<ProjectsPage />} />
          </Routes>
        </div>
    </Router>
  );
};

export default App;