// ProjectsPage.jsx
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './ProjectsPage.scss';

import ASL from './ASL.mp4'
import mandelbrot from './mandelbrot.mp4'
import jobot from './jobot.mp4'
import kabutar from './kabutar.mp4'

import asl_img from './asl.webp'
import mandelbrot_img from './mandelbrot.webp'
import jobot_img from './jobot.webp'
import kabutar_img from './kabutar.webp'
import crs_img from './crs.webp'

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const projectVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100
    }
  }
};

const ProjectsPage = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  const projects = [
    {
      id: 1,
      title: "ASL Sign Language Interpreter",
      description: "This project leverages computer vision and machine learning to identify American Sign Language (ASL) hand signs and translate them into text displayed on a monitor. Using a robust combination of deep learning frameworks and real-time video processing, the system detects hand gestures with high accuracy and converts them into their corresponding alphabet or word representation.",
      image: asl_img,
      video: ASL,
      timeframe: "Aug 2023 - Sept 2023",
      techStack: ["Python", "OpenCV", "MediaPipe", "RandomForest Classifier"]
    },
    {
      id: 2,
      title: "Mandelbrot Set Explorer",
      description: "The Mandelbrot Set Explorer is an interactive tool designed to visualize and navigate the mesmerizing complexity of fractals. Built using the Taichi Python library, this project utilizes GPU-accelerated parallel computing to deliver high-performance rendering of the Mandelbrot Set, offering users a smooth and engaging exploration experience.",
      image: mandelbrot_img,
      video: mandelbrot,
      timeframe: "Nov 2023 - Dec 2023",
      techStack: ["Python", "Taichi"]
    },
    {
      id: 3,
      title: "Kabutar",
      description: "Kabutar is a full-fledged social media application designed with a focus on seamless communication and connectivity. Built using the same architectural principles as Facebook, it emphasizes real-time messaging to enhance user interaction and engagement. This project showcases a blend of advanced frontend and backend technologies to deliver a scalable and intuitive user experience.",
      image: kabutar_img,
      video: kabutar,
      timeframe: "May 2023 - June 2023",
      techStack: ["React", "Node", "Express", "MongoDB"]
    },
    {
      id: 4,
      title: "Jobot: AI Job Application Bot",
      description: "Jobot is an intelligent automation tool designed to streamline the job application process by leveraging AI. Specifically tailored for platforms like Workday, this bot automates the entire application workflow, significantly reducing the time and effort required for job seekers. Jobot combines the power of Selenium, ChatGPT API, and LangChain to ensure precision and adaptability during the application process.",
      image: jobot_img,
      video: jobot,
      timeframe: "Aug 2023 - Nov 2023",
      techStack: ["Python", "Selenium","Langchani", "OpenAI API"]
    },
    {
      id: 5,
      title: "Course Recommendation System",
      description: "This Course Recommendation System is an intelligent platform designed to assist students in discovering courses tailored to their interests and academic goals. By integrating advanced algorithms and a conversational AI chatbot, the system provides a seamless and personalized experience for students exploring educational opportunities.",
      image: crs_img,
      timeframe: "Dec 2022 - Feb 2024",
      techStack: ["ReactJS", "Node.js", "SQL", "AWS"]
    }
  ];

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const closePopup = () => {
    setSelectedProject(null);
  };

  return (
    <div className="projects-page">
      <motion.h1 
        className="page-title"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Projects
      </motion.h1>
      <motion.div 
        className="projects-grid"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {projects.map((project) => (
          <motion.div
            key={project.id}
            className="project-card"
            variants={projectVariants}
            whileHover={{ scale: 1.02 }}
            onClick={() => handleProjectClick(project)}
          >
            <div className="project-image">
              <img src={project.image} alt={project.title} />
              <div className="hover-overlay">
                <span>View Details</span>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>

      <AnimatePresence>
        {selectedProject && (
          <motion.div 
            className="popup-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closePopup}
          >
            <motion.div 
              className="popup-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="popup-left">
                {selectedProject.video ? (
                  <video autoPlay loop muted>
                    <source src={selectedProject.video} type="video/mp4" />
                  </video>
                ) : (
                  <img src={selectedProject.image} alt={selectedProject.title} />
                )}
              </div>
              <div className="popup-right">
                <h2>{selectedProject.title}</h2>
                <p className="description">{selectedProject.description}</p>
                <div className="timeframe">
                  <h3>Timeframe</h3>
                  <p>{selectedProject.timeframe}</p>
                </div>
                <div className="tech-stack">
                  <h3>Tech Stack</h3>
                  <div className="tech-tags">
                    {selectedProject.techStack.map((tech, index) => (
                      <span key={index} className="tech-tag">{tech}</span>
                    ))}
                  </div>
                </div>
                <button className="close-button" onClick={closePopup}>
                  ×
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProjectsPage;