import React from 'react';

function SmokyGradientBackground() {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh', // Or any desired height (e.g., "100%")
        overflow: 'hidden',
        background: '#000', // fallback color in case SVG fails
      }}
    >
      <svg
        aria-hidden="true"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'block',
        }}
      >
        <defs>
          {/* 
            1) Define a black-to-white linear gradient.
               You can tweak the x1/y1/x2/y2 to change gradient direction.
          */}
          <linearGradient id="blackWhiteGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#000" />
            <stop offset="100%" stopColor="#fff" />
          </linearGradient>

          <filter id="smokeFilter">
            {/*
              2) Generate fractal noise with feTurbulence.
                 Animate the 'seed' so the pattern shifts over time.
            */}
            <feTurbulence
              type="fractalNoise"
              baseFrequency="0.015"
              numOctaves="3"
              seed="2"
              result="turbulence"
            >
              <animate
                attributeName="seed"
                from="0"
                to="100"
                dur="15s"
                repeatCount="indefinite"
              />
            </feTurbulence>

            {/*
              3) Displace the SourceGraphic (the rect below) with the turbulence data.
            */}
            <feDisplacementMap
              in="SourceGraphic"
              in2="turbulence"
              scale="30"
              xChannelSelector="R"
              yChannelSelector="G"
            />
          </filter>
        </defs>

        {/*
          4) The <rect> is filled by our gradient, then passed through the
             smoke filter for that warped, smoky look.
        */}
        <rect
          width="100%"
          height="100%"
          fill="url(#blackWhiteGradient)"
          filter="url(#smokeFilter)"
        />
      </svg>
    </div>
  );
}

export default SmokyGradientBackground;
