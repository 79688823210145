// AboutPage.jsx
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaGithub, FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import './AboutPage.scss';
import img1 from '../About/img1.jpeg'
import img2 from '../About/img2.jpeg'
import img3 from '../About/img3.jpeg'
import img4 from '../About/img4.jpeg'

const AboutPage = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  // Add tooltips to your images
  const images = [
    { src: img1, alt: 'Image 1', tooltip: 'Professional Headshot' },
    { src: img2, alt: 'Image 2', tooltip: 'Working on Projects' },
    { src: img3, alt: 'Image 3', tooltip: 'Team Collaboration' },
    { src: img4, alt: 'Image 4', tooltip: 'Speaking at Conference' },
  ];

  const socialLinks = [
    { icon: FaGithub, url: 'https://github.com/jasvithchand', label: 'GitHub' },
    { icon: FaLinkedin, url: 'https://www.linkedin.com/in/jasvith/', label: 'LinkedIn' },
    { icon: FaEnvelope, url: 'mailto:jasvithanne@gmail.com', label: 'Email' }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <motion.div 
      className="about-page"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div 
        className="about-left"
        variants={itemVariants}
      >
        <div className="gallery-grid">
          {images.map((image, index) => (
            <motion.div 
              key={index}
              className="gallery-item"
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              onClick={() => setSelectedImage(image)}
              data-tooltip-id={`image-tooltip-${index}`}
              data-tooltip-content={image.tooltip}
            >
              <img src={image.src} alt={image.alt} />
              <Tooltip
                id={`image-tooltip-${index}`}
                place="bottom"
                effect="solid"
                className="tooltip-custom"
                delayShow={200}
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.9)',
                  color: '#ffffff',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  fontSize: '14px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                }}
              />
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {selectedImage && (
            <motion.div 
              className="zoomed-view"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setSelectedImage(null)}
            >
              <motion.img
                src={selectedImage.src}
                alt={selectedImage.alt}
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.8 }}
                transition={{ type: "spring", stiffness: 300 }}
              />
              <button 
                className="close-button"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedImage(null);
                }}
              >
                ×
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      <motion.div 
        className="about-right"
        variants={itemVariants}
      >
        <motion.div 
          className="about-content"
          variants={itemVariants}
        >
          <motion.h1
            variants={itemVariants}
          >
            About Me
          </motion.h1>
          
          <motion.div 
            className="about-description"
            variants={itemVariants}
          >
            <p>
            I like coding cool stuff, mainly using Python and ReactJS. I proudly call myself a Software Engineer,
            which is just a fancy way of saying I spend most of my time arguing with bugs and occasionally winning.
             For me, coding isn’t just work—it’s a satisfying mix of art, logic, and a sprinkling of controlled chaos.
            </p>
            <p>
            When I’m not deep in code, you’ll find me absolutely owning FPS games (or so I tell myself), snapping photos o
            f anything that looks remotely artsy, or debating the finer points of Marvel movies. Books? They’re my backup plan
             when the Wi-Fi is down. I’m also a tech enthusiast, always tinkering with new tools and wondering why I didn’t
            invent them first.
            </p>
            <p>
            Recently, I’ve ventured into the wild world of traveling—turns out, there’s a whole planet outside my code editor!
             Exploring new places, meeting interesting people, and occasionally getting lost has been a refreshing way to 
             recharge my creativity (and my Google Maps skills).
            </p>
          </motion.div>

          <motion.div 
            className="social-links"
            variants={itemVariants}
          >
            <h2>Connect With Me</h2>
            <div className="social-icons">
              {socialLinks.map((social, index) => {
                const Icon = social.icon;
                return (
                  <motion.a
                    key={index}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.2, y: -5 }}
                    whileTap={{ scale: 0.9 }}
                    aria-label={social.label}
                  >
                    <Icon />
                  </motion.a>
                );
              })}
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default AboutPage;