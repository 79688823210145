// CustomCursor.jsx
import React, { useState, useEffect } from 'react';
import './cursor.scss';

const CustomCursor = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: -100, y: -100 });
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    // Handle window resize
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    // Handle cursor position
    const updateCursorPosition = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    // Only add event listeners if on desktop
    if (isDesktop) {
      window.addEventListener('mousemove', updateCursorPosition);
    }

    // Add resize listener
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('mousemove', updateCursorPosition);
      window.removeEventListener('resize', handleResize);
    };
  }, [isDesktop]);

  // Don't render the cursor on mobile
  if (!isDesktop) return null;

  return (
    <div 
      className="custom-cursor" 
      style={{ 
        left: `${cursorPosition.x}px`, 
        top: `${cursorPosition.y}px` 
      }} 
    />
  );
};

export default CustomCursor;