// Navbar.jsx
import * as React from 'react';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import './Navbar.scss';

const Navbar = () => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [transitionPhase, setTransitionPhase] = useState('initial');
  const [showTransitionText, setShowTransitionText] = useState(false);
  const [transitionPosition, setTransitionPosition] = useState({ x: 0, y: 0 });
  const location = useLocation();
  const navigate = useNavigate();

  const getNavText = () => {
    switch (location.pathname) {
      case '/about':
        return 'Home';
      case '/projects':
        return 'Home';
      default:
        return 'About';
    }
  };

  const getTargetRoute = () => {
    switch (location.pathname) {
      case '/about':
        return '/';
      case '/projects':
        return '/';
      default:
        return '/about';
    }
  };

  const handleTransition = (e) => {
    const clickX = e.clientX;
    const clickY = e.clientY;
    setTransitionPosition({ x: clickX, y: clickY });
    
    setIsTransitioning(true);
    setTransitionPhase('expand');
    
    setTimeout(() => {
      setShowTransitionText(true);
    }, 600);

    setTimeout(() => {
      setShowTransitionText(false);
    }, 1200);

    setTimeout(() => {
      navigate(getTargetRoute());
      setTransitionPhase('contract');
    }, 1400);

    setTimeout(() => {
      setIsTransitioning(false);
      setTransitionPhase('initial');
    }, 2000);
  };

  const getTransitionVariants = () => ({
    initial: {
      scale: 1,
      x: transitionPosition.x,
      y: transitionPosition.y,
    },
    expand: {
      scale: [1, 100],
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
      transition: { duration: 0.7, ease: [0.4, 0, 0.2, 1] }
    },
    contract: {
      scale: [100, 1],
      x: transitionPosition.x,
      y: transitionPosition.y,
      transition: { duration: 0.7, ease: [0.4, 0, 0.2, 1] }
    }
  });

  return (
    <>
      <nav className="navbar">
        <div className="navbar__container">
          <div className="navbar__menu">
            <motion.button 
              className="navbar__link"
              onClick={handleTransition}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={isTransitioning}
            >
              <span className="navbar__text">
                {getNavText()}
              </span>
              <motion.div 
                className="navbar__circle"
                layoutId="circle"
              />
            </motion.button>
          </div>
        </div>
      </nav>
      
      <AnimatePresence mode="wait">
        {isTransitioning && (
          <motion.div
            className="page-transition"
            variants={getTransitionVariants()}
            initial="initial"
            animate={transitionPhase}
          />
        )}

        {showTransitionText && (
          <motion.div
            className="transition-text"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {getNavText()}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;